import * as React from "react"
import { Link } from "gatsby"
import { Parallax } from "@react-spring/parallax"
import { Themed } from "theme-ui"
import Layout from "@lekoarts/gatsby-theme-cara/src/components/layout"
import Divider from "@lekoarts/gatsby-theme-cara/src/elements/divider"
import Graph from "../components/graph"
import { UpDownWide } from "@lekoarts/gatsby-theme-cara/src/styles/animations"
import Svg from "@lekoarts/gatsby-theme-cara/src/components/svg"
import Seo from "@lekoarts/gatsby-theme-cara/src/components/seo"
import Content from "@lekoarts/gatsby-theme-cara/src/elements/content"
import Inner from "@lekoarts/gatsby-theme-cara/src/elements/inner"

import image from "../../static/inflation-image.png"
import logo from "../../static/logo-designs-06.svg"
import Hbmenu from "../components/hbmenu"

const NotFound = () => (
  <Layout>
    <Seo title="africoin" />
    <Parallax pages={2}>
      <div>
        <Divider speed={0.2} offset={0} factor={1}>
          {/* <UpDown>
            <Svg icon="triangle" hiddenMobile width={48} stroke color="icon_orange" left="10%" top="20%" />
            <Svg icon="hexa" width={48} stroke color="icon_red" left="60%" top="70%" />
            <Svg icon="box" width={6} color="icon_darker" left="60%" top="15%" />
          </UpDown> */}
          <UpDownWide>
            <Svg icon="arrowUp" hiddenMobile width={16} color="icon_blue" left="80%" top="10%" />
            <Svg icon="triangle" width={12} stroke color="icon_brightest" left="90%" top="50%" />
            <Svg icon="circle" width={16} color="icon_darker" left="70%" top="90%" />
            <Svg icon="triangle" width={16} stroke color="icon_darkest" left="30%" top="65%" />
            <Svg icon="cross" width={16} stroke color="icon_pink" left="28%" top="15%" />
            <Svg icon="circle" width={6} color="icon_darkest" left="75%" top="10%" />
            <Svg icon="upDown" hiddenMobile width={8} color="icon_darkest" left="45%" top="10%" />
          </UpDownWide>
          <Svg icon="circle" hiddenMobile width={24} color="icon_darker" left="5%" top="70%" />
          <Svg icon="circle" width={6} color="icon_darkest" left="4%" top="20%" />
          {/* <Svg icon="circle" width={12} color="icon_darkest" left="50%" top="60%" /> */}
          <Svg icon="upDown" width={8} color="icon_darkest" left="95%" top="90%" />
          <Svg icon="upDown" hiddenMobile width={24} color="icon_darker" left="40%" top="80%" />
          <Svg icon="triangle" width={8} stroke color="icon_darker" left="25%" top="5%" />
          <Svg icon="circle" width={64} color="icon_green" left="95%" top="5%" />
          {/* <Svg icon="box" hiddenMobile width={64} color="icon_purple" left="5%" top="90%" />
          <Svg icon="box" width={6} color="icon_darkest" left="10%" top="10%" /> */}
          <Svg icon="box" width={12} color="icon_darkest" left="40%" top="30%" />
          <Svg icon="hexa" width={16} stroke color="icon_darker" left="10%" top="50%" />
          <Svg icon="hexa" width={8} stroke color="icon_darker" left="80%" top="70%" />
        </Divider>
        <Hbmenu />
        <Content sx={{ variant: `texts.bigger` }} speed={0.4} offset={0} factor={1}>
          <Inner>
            <Themed.h1 style={{paddingTop: 200}}>africoin</Themed.h1>
            <Themed.h5>
              African countries are subject to very high rates of inflation often in the two digits per year. This means a loss of purchase and saving power for African people.
              <br></br><br></br>Africoin is a stablecoin pegged to the US Dollar which serves as a hedge against high inflation and an international African coin.
            </Themed.h5>
          </Inner>
          {/* <div dangerouslySetInnerHTML={{ __html: Graph }} style={{maxWidth: 600, paddingTop: 30, paddingBottom: 30}} /> */}
          <img src={image} alt="inflation image" style={{width: 600, paddingTop: 30, paddingBottom: 30}} />
          <Themed.p>
            Go back to <Link to="/">homepage</Link>.
          </Themed.p>
          <img src={logo} alt="logo ethafrica" style={{width: 200}} />
        </Content>
      </div>
    </Parallax>
  </Layout>
)

export default NotFound



